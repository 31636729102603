import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Select from '@paljs/ui/Select';
import axios from 'axios';
import SEO from '../../components/SEO';
import Auth from '../../components/Auth';
import { baseUri, checkIsAdmin, hideLoader, requireCheck, showLoader } from '../../utils/utils';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import Error from '../404';
import toast, { Toaster } from 'react-hot-toast';
export default function ResetPassword() {
  const authState = useContext(GlobalStateData);
  const [emailOptions, setEmailOptions] = useState([]);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const clearFormData=()=>{
    setId('');
    setPassword('');
    setRePassword('');
  }
  const getApi = () => {
    try {
      axios
        .get(`${baseUri()}users`, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          const optionsArr: any = [];
          response.data.forEach((t: any) => {
            optionsArr.push({
              label: `${t.email}`,
              value: t.id,
            });
          });
          setEmailOptions(optionsArr);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const postApi = () => {
    const postData = {
      data: {
        id,
        password,
        repassword,
      },
    };
    toast.dismiss();
    let validate = requireCheck(postData);
    if (!validate) return;
    if (!id) {
      toast.error('Please choose an email');
      return;
    }
    if (password !== repassword) {
      toast.error("Passwords doesn't match");
      return;
    }
    if (password.length < 6) {
      toast.error('Password must be atleast 6 characters');
      return;
    }
    showLoader();
    axios
      .put(
        `${baseUri()}users/${id}`,
        { password },
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        clearFormData();
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (authState && authState.authEmail && checkIsAdmin(authState.authEmail)) {
      showLoader();
      getApi();
    }
  }, []);
  if (authState && authState.authEmail && !checkIsAdmin(authState.authEmail)) {
    return <Error />;
  }
  return (
    authState &&
    checkIsAdmin(authState.authEmail) && (
      <Auth title="Reset Password" subTitle="Please set a new password for the existing account">
        <SEO title="Reset Password" />
        <form>
          <Toaster />
          <Select
            options={emailOptions}
            placeholder="Enter email"
            onChange={(event: any) => {
              setId(event.value);
            }}
            className="email id"
          />
          <InputGroup fullWidth>
            <input
              type="password"
              placeholder="New Password (min 6 characters)"
              minLength={6}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="password require"
            />
          </InputGroup>
          <InputGroup fullWidth>
            <input
              type="password"
              placeholder="Confirm Password (min 6 characters)"
              minLength={6}
              value={repassword}
              onChange={(e) => setRePassword(e.target.value)}
              className="rePassword require"
            />
          </InputGroup>
          <Button status="Success" disabled={id===''} type="button" shape="SemiRound" fullWidth onClick={postApi}>
            Change Password
          </Button>
        </form>
      </Auth>
    )
  );
}
